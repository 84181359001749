<template>
  <v-carousel
      v-if="viewType !== 'xs'"
      cycle
      interval="10000"
      class="carousel"
      :height="viewType !== 'sm' ? '48vh' : '85vh'"
      hide-delimiter-background
      delimiter-icon="fas fa-dice-d20"
      next-icon="fas fa-angle-right"
      prev-icon="fas fa-angle-left"
      :style="viewType === 'sm' ? 'margin-top: 0px' : ''"
  >
      <template>

      </template>

    <v-carousel-item
        :src="require('@/assets/imgs/carousel/iv-br-carousel.png')"
    >
      <div class="content">
        <header :class="viewType !== 'sm' && viewType !== 'xs'  ? 'text-h1' : 'text-h4'">IV-KON GROUP</header>
        <span :class="viewType !== 'sm' && viewType !== 'xs' ? 'text-h4' : 'text-h6'" class="grey--text">Автоматизация, Разработка, Внедрение</span>

        <div :class="viewType !== 'sm' ? 'text' : 'sm-text'">
          <header class="text-h6">Новости</header>
          <div>
            Предлагаем вашему вниманию обширный список программного обеспечения для ПК
            <br/>
            Переходи по <a class="link--text" href="/article/software">ссылке</a>  и получай самое интересное
          </div>
        </div>
      </div>
    </v-carousel-item>


    <v-carousel-item
        :src="require('@/assets/imgs/carousel/n-br-carousel.png')"
    >
      <div class="content">
        <header :class="viewType !== 'sm' && viewType !== 'xs'  ? 'text-h1' : 'text-h3'">IV-KON GROUP</header>
        <span :class="viewType !== 'sm' && viewType !== 'xs' ? 'text-h4' : 'text-h5'" class="grey--text">Автоматизация, Разработка, Внедрение</span>

        <div :class="viewType !== 'sm' ? 'text' : 'sm-text'">
          <header class="text-h6">О нас</header>
          <div>
            Мы посвящаем время и усилия исследованию бизнес-процессов и устранением проблем современными методами.
            <br/>
            Используем уникальный подход к внедрению или разработки программного обеспечения посредством анализа.
            <br/>
            Наша компания предлагает сделать будущее удобней, чем сегодня.
            <br/>
            Инновации и быстрый рост информационных систем в мире способствует к упрощению работы с вашим бизнесом.
          </div>
        </div>

      </div>
    </v-carousel-item>


  </v-carousel>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Carousel",
  computed: {
    viewType() {
      return this.$vuetify.breakpoint.name
    }
  }

}
</script>

<style lang="scss" scoped>
.carousel {
  margin-top: 7vh;
}
.text {
  margin-top: 2vh;
  color: white;
  padding: 2vh 1vw 2vh 1vw;
  background: rgba(14, 13, 13, 0.45);
  border-radius: 8px;
header{
  margin: 0 0 2vh 0;
}
}
.sm-text {
  margin-top: 2vh;
  color: white;
  padding: 2vh 1vw 2vh 1vw;
  background: rgba(14, 13, 13, 0.45);
  border-radius: 8px;
  font-size: 10px;
  header{
    margin: 0 0 2vh 0;
    font-size: 15px !important;
  }
  div{
    font-size: 12px !important;
  }
}
</style>