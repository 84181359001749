<template>
    <transition name="bounce" mode="out-in">
      <v-card v-if="!isSent" key="form" min-height="46vh" >
        <v-progress-linear v-if="loader" color="base" indeterminate/>
        <v-card-title class="pa-6 pb-2">Обратная связь</v-card-title>

        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" sm="6" md="6" class="pa-4">
              <v-form ref="form" v-model="valid">
                <v-text-field
                    v-model="contact.firstName"
                    label="Имя *"
                    :rules="rAll"
                    required
                    outlined
                    :disabled="loader"
                    @keydown.enter="send"
                />
                <v-text-field
                    v-model="contact.email"
                    label="Email *"
                    :rules="rEmail"
                    required
                    outlined
                    :disabled="loader"
                    placeholder="name@example.com"
                    @keydown.enter="send"

                />
                <v-text-field
                    v-model="contact.phone"
                    label="Контактный телефон"
                    placeholder="+77780030288"
                    outlined
                    @keydown.enter="send"
                    :disabled="loader"
                />
              </v-form>
            </v-col>

            <v-col cols="12" sm="6" md="6" class="pa-4">
              <v-textarea
                  v-model="message"
                  label="Текст сообщения"
                  height="25vh"
                  class=" "
                  hide-details
                  :disabled="loader"
                  outlined
                  @keydown.enter="send"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>


        <v-card-actions class="pa-6 pt-2">
          <v-spacer/>
          <v-btn dark color="base" @click="send">Отправить</v-btn>
        </v-card-actions>
      </v-card>


      <v-card v-else key="dane">
        <v-card-text>
          <div class="d-flex" style="flex-direction: column; align-items: center; justify-content: center; min-height: 46vh; border: #4cae50 2px solid; border-radius: 4px ">
            <v-icon color="green" size="64">fas fa-check-circle</v-icon>
            <br/>
            <h1 class="text-h5">Сообщение отправлено</h1>
            <h3>Мы свяжемся с вами в ближайшее время</h3>
          </div>
        </v-card-text>
      </v-card>
    </transition>
</template>

<script>
export default {
  name: "IvReLink",
  props: {
    from: {
      default: "Основная",
      type: String
    }
  },
  data() {
    return {
      loader: false,
      isSent: false,

      chtid: "-1001829794379",
      tkn: "5546969399:AAGS6Xtc5gXnND-DMAygYQaLqkdTuJZjbys",

      contact: {
        firstName: undefined,
        email: undefined,
        phone: undefined,
      },
      message: " ",

      valid: false,
      rAll: [
        v => !!v || 'Поле обязательно к заполнению'
      ],
      rEmail: [
        v => !!v || 'Поле обязательно к заполнению',
        v => /.+@.+/.test(v) || 'Не крректный email'
      ],
    }
  },
  methods: {
    async fetch() {
      let v = this.contact
      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          parse_mode: 'html',
          chat_id: this.chtid,
          text: `<b>Имя: </b>${v.firstName}\n<b>Email: </b>${v.email}\n<b>Номер телефона: </b>${v.phone}\n<b>От куда: </b>${this.from}\n<b>Сообщение:</b>\n<i>${this.message}</i>`,
        })
      };
      await fetch("https://api.telegram.org/bot" + this.tkn + "/sendMessage", payload);
    },
    send() {
      if(this.valid) {
        this.loader = true
        setTimeout(() => {
          this.fetch()
          this.loader = false
          this.$emit('close')
          this.isSent = true
        }, 1000)
      } else {
        this.$refs.form.validate()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.3);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>