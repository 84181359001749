<template>
  <div>
    <h3 class="text-h3 mt-2 mb-2">Услуги</h3>
    <v-row v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
      <v-col cols="6" v-for="i in services" :key="i.id">
        <v-hover
            v-slot="{ hover }"
        >
          <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              class="mt-2 mb-8"
              dark
              @click="$router.push(i.href)"

          >
            <v-img
                class="g-img"
                :lazy-src="i.src"
                :src="i.src"
                min-height="12vh"
            />
            <v-card-title
                class="subtitle-2"
                style=" word-break: normal; min-height: 12vh; align-content: start; border: 0 black solid;"
            >
              {{i.title}}
            </v-card-title>
          </v-card>
        </v-hover>

      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols=12 md="3" v-for="i in services" :key="i.id">
        <v-hover
            v-slot="{ hover }"
        >
        <v-card
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            class="mt-2 mb-8"
            dark
            @click="$router.push(i.href)"

        >
            <v-img
                :lazy-src="i.src"
                :src="i.src"
                min-height="22vh"
            />
            <v-card-title
                class="subtitle-1"
                style=" word-break: normal; min-height: 12vh; align-content: start"
            >
              {{i.title}}
            </v-card-title>
          </v-card>
        </v-hover>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Services",
  data() {
    return {
      services: [
          {id: 1,
            title: "Внедрение систем складского учёта и розничной торговли",
            src: require('@/assets/imgs/services/auto-1.png'),
            href: 'article/my-warehouse'
          },
          {id: 2,
              title: "Автоматизация гостиничного бизнеса",
              src: require('@/assets/imgs/services/woo-1.png'),
              href: 'article/woo-book'
          },
          {id: 3,
              title: "Внедрение систем управления взаимоотношения с клиентами CRM",
              src: require('@/assets/imgs/services/crm-1.png'),
              href: 'article/amo-crm'
          },
          {id: 4,
              title: "Разработка индивидуальных учётных систем",
              src: require('@/assets/imgs/services/dev-1.png'),
              href: 'article/development'
          },
          {id: 5,
              title: "Создание чат ботов",
              src: require('@/assets/imgs/services/bot-1.png'),
              href: 'article/chat-bots'
          },
          {id: 6,
            title: "Лицензии Microsoft, Kaspersky, Pro32",
            src: require('@/assets/imgs/services/soft-1.png'),
            href: 'article/software'
          },
      ],
    }
  }
}
</script>

<style lang="sass" scoped>

.v-card.on-hover.theme--dark
  background-color: rgba(#262626FF, 0.6)
  >.v-card__text
    color: #000
</style>