import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'Base'
    }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import('../views/article/'),
    meta: {
      layout: 'Base'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/'),
    meta: {
      layout: 'Base'
    },
  },
  {
    path: '*',
    name: 'error',
    component: () => import('../views/error/'),
    meta: {
      layout: 'Empty'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
