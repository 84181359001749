import { render, staticRenderFns } from "./IvFooter.vue?vue&type=template&id=15e4d8f0&scoped=true&"
import script from "./IvFooter.vue?vue&type=script&lang=js&"
export * from "./IvFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e4d8f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VCard,VCardText,VDivider,VFooter})
