import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#ababab",
                base: "#D50000",
                link: "#2196F3",
            }
        }
    },
});
