<template>
  <v-app class="app">
    <iv-nav/>
    <v-main>
      <v-container class="ma-0 pa-0" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import IvNav from "@/components/IvNav";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Empty",
  components: {IvNav}
}
</script>