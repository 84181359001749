<template>
    <div class="pa-0 ma-0">
      <Carousel/>
      <div class="content">
        <v-row
            align="start"
            justify="center"
            dense
        >
          <v-col cols="12" md="12">
            <Services/>
          </v-col>
          <v-col cols="12" md="12">
            <Message/>
          </v-col>
        </v-row>
      </div>
    </div>
</template>

<script>
import Message from "@/views/home/components/HomeMessage.vue";
import Services from "@/views/home/components/HomeServices.vue";
import Carousel from "@/views/home/components/HomeCarousel.vue";

export default {
  components: {Carousel, Message, Services },
}
</script>
