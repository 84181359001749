<template>
  <v-app-bar app elevation="0" color="grey darken-4" :min-height="$vuetify.breakpoint.name === 'xs' || 'sm' ? '58px' : '100%' " >
    <v-row
        class="bar"
        align="end"
        justify="end"
    >
      <v-col align-self="end">
        <a class="home" href="/">
          <div style="display: flex; align-items: center; justify-self: center">
            <v-img class="img" :lazy-src="require('@/assets/logo.png')" :src="require('@/assets/logo.png')" alt="logo">
              <div class="home chicle"></div>
            </v-img>
            <h3 v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'" class="text-logo">
              IV-KON
              <br>GROUP

            </h3>
          </div>
        </a>
      </v-col>

      <v-col offset="3" offset-sm="3" offset-md="7" align-self="center">
        <iv-contacts social small emit-class="mx-2"/>
        <iv-contacts phone emit-class="white--text ml-2"/>
      </v-col>

<!--      <a class="white&#45;&#45;text">О нас</a>-->

    </v-row>
  </v-app-bar>
</template>

<script>
import {last} from "eslint-plugin-vue/lib/utils/indent-utils";
import IvContacts from "@/components/IvContacts.vue";

export default {
  name: 'VikNav',
  components: {IvContacts},
  data() {
    return {
      links: [
        {
          id: 1,
          href: "https://instagram.com/iv_kon_group?igshid=YmMyMTA2M2Y=",
          icon: "fab fa-instagram"
        },
        {
          id: 2,
          href: "https://t.me/ivkongroup",
          icon: "fab fa-telegram-plane"
        },
        {
          id: 3,
          href: "https://api.whatsapp.com/send/?phone=77780030288",
          icon: "fab fa-whatsapp"
        },
      ],
    }
  },
  methods: {
    last,
    home() {
      if(this.$router.currentRoute.path === '/') return
      this.$router.push('/')
    }
  },
}
</script>

<style scoped lang="scss">

.home {
  text-decoration: none;
  .chicle{
    min-height: 52px;
    min-width: 52px;
  }
}

.home:hover{
  .chicle{
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
  }
  .text-logo{
    border-color: #e0e0e0 !important;
    color: #e0e0e0 !important;
    background: #1b1b31;
  }
}

.img{
  max-height: 58px;
  max-width: 58px;
  @media screen and (min-width: 600px) {
    max-height: 52px;
    max-width: 52px;
  }
  @media screen and (max-width: 600px) {
    max-height: 48px;
    max-width: 48px;
  }
}
.hov.on-hover.theme--dark {
  background-color: rgba(#FFF, 0.8);
  .v-card-text {
   color: #000
  }
}
</style>