<template>
  <div class="mb-16">
    <h3 class="text-h3 mt-2 mb-2">Напиши нам</h3>
    <v-row dense>
      <v-col cols="12" md="4">
        <div class="grey--text mr-16">
          Поделитесь мнением о нашей работе или задайте нам любой интересующий вас вопрос в поле комментарий
          <br/>
          <br/>
          Телефон: <iv-contacts phone emit-class="white--text"/>
          <br/>
          Email: <iv-contacts mail emit-class="white--text"/>
          <br/>
          Социальные сети: <iv-contacts social/>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <iv-re-link/>
      </v-col>



    </v-row>
  </div>
</template>

<script>
import IvContacts from "@/components/IvContacts.vue";
import IvReLink from "@/views/modules/relink/IvReLink.vue";

export default {
  components: {IvReLink, IvContacts},
  name: "HomeMessage",
}
</script>

<style scoped>

</style>