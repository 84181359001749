<template>
  <v-app class="app">
    <iv-nav/>
    <v-main>
      <v-container class="base-container ma-0 pa-0">
        <router-view />
      </v-container>
      <iv-footer/>
    </v-main>
  </v-app>
</template>

<script>
import IvFooter from "@/components/IvFooter.vue";
import IvNav from "@/components/IvNav.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Mobile",
  components: {IvFooter, IvNav}
}
</script>

<style scoped>

</style>