<template>
  <v-app class="app">
    <iv-nav/>
    <v-main class="bg">
      <v-container class="base-container ma-0 pa-0">
        <router-view />
      </v-container>
      <iv-footer/>
    </v-main>
  </v-app>
</template>

<script>
import IvNav from "@/components/IvNav";
import IvFooter from "@/components/IvFooter";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Base",
  components: {IvFooter, IvNav}
}
</script>

<style lang="scss" scoped>
.base-container {
  min-width: 100%;
  height: 150gd;
}
</style>