<template>
  <v-footer
      class="mt-4"
      padless

  >
    <v-card
        flat
        tile
        class=" lighten-1 white--text text-center"
        color="grey darken-4"
    >
      <v-card-text>
        <iv-contacts social large emit-class="mx-4" size="24px"/>
      </v-card-text>

      <v-card-text class="white--text pt-0" style="min-width: 100vw">
        За подробностями можете ображаятся по телефону <iv-contacts phone emit-class="white--text"/>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>IV-KON</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import IvContacts from "@/components/IvContacts.vue";

export default {
  name: "IvFooter",
  components: {IvContacts},
}
</script>

<style scoped>

</style>