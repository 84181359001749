<template>
  <span v-if="social">
    <v-btn
        v-for="link in links"
        :key="link.id"
        :href="link.href"
        :class="emitClass"
        class="white--text"
        icon
        :small="small"
        :large="large"
    >
      <v-icon :size="size">
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </span>
  <span v-else-if="phone">
    <a :class="emitClass" class="astyle"  href="tel:+77780030288">+77780030288</a>
  </span>
  <span v-else-if="mail">
    <a :class="emitClass" class="astyle"  href="mailto: hello@iv-kon.kz">hello@iv-kon.kz</a>
  </span>
  <span v-else>

  </span>
</template>

<script>
export default {
  name: "IvContacts",
  props: {
    small: {
      default: false,
      type: Boolean
    },
    large: {
      default: false,
      type: Boolean
    },
    size: {
      default: "16px",
      type: String
    },
    social: {
      default: false,
      type: Boolean
    },
    phone: {
      default: false,
      type: Boolean
    },
    mail: {
      default: false,
      type: Boolean
    },
    emitClass: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      links: [
        {
          id: 1,
          href: "https://instagram.com/iv_kon_group?igshid=YmMyMTA2M2Y=",
          icon: "fab fa-instagram"
        },
        {
          id: 2,
          href: "https://t.me/ivkongroup",
          icon: "fab fa-telegram-plane"
        },
        {
          id: 3,
          href: "https://api.whatsapp.com/send/?phone=77780030288",
          icon: "fab fa-whatsapp"
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.astyle:link {
  color: #2196F3;
  text-decoration: none;
}
.astyle:visited {
  text-decoration: none;
}
/* mouse over link */
.astyle:hover {
  text-decoration: underline;
}
</style>