<template>
  <component :is="layout" />
</template>

<script>
import Empty from "@/layouts/Empty";
import Base from "@/layouts/Base";
import Mobile from "@/layouts/Mobile";

export default {
  name: 'App',
  components: {Empty, Base, Mobile},
  computed: {
    layout() {
      if(this.$vuetify.breakpoint.name === "xs") return "mobile"
      if(this.$vuetify.breakpoint.name === "sm") return "mobile"
      if (this.$route.name === "error") return "empty"
      return (this.$route.meta.layout || "empty")
    },
  },
};
</script>

<style lang="scss">
@import "assets";
</style>
